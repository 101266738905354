<template>
    <div class="leisure_buttons_group">
<!--        <router-link tag="button" class="btn_minigame_comp bw4" :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_DONGHANG}"-->
<!--                     :to="{path:'/leisure/powerball', query: {t: new Date().getTime()}}">-->
<!--           동행복권-->
<!--        </router-link>-->

        <router-link tag="button" class="btn_minigame_comp bw4" :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_BET365}"
                     :to="{path:'/leisure/bet365_superleague', query: {t: new Date().getTime()}}">
           BET365
        </router-link>


<!--        <router-link tag="button" class="btn_minigame_comp bw4" :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_LOTUS}"-->
<!--                     :to="{path:'/leisure/lotus_bakara', query: {t: new Date().getTime()}}">로투스</router-link>-->

<!--        <router-link tag="button" class="btn_minigame_comp bw4" :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_CROWN}"-->
<!--                     :to="{path:'/leisure/sutda', query: {t: new Date().getTime()}}">크라운</router-link>-->

<!--        <router-link tag="button" class="btn_minigame_comp bw4" :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_SKY}"-->
<!--                     :to="{path:'/leisure/sky_speed_bakara', query: {t: new Date().getTime()}}">스카이파크</router-link>-->
    </div>
</template>
<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureGameCompLinks",
        data(){
            return {
                leisureConst:leisureConst,
            }
        },
        methods:{
            commingsoon(){
                alert('준비중입니다')
            },
        }
    }
</script>

<style scoped>

</style>