<template>
  <div>

    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>
    <div class="content_section">
      <div class="content">
        <sub-title><span>{{i18nConst.MENU['VSPORTS'][$store.state.country]}}</span></sub-title>
        <leisure-game-comp-links></leisure-game-comp-links>
<!--        <leisure-comp-donghang-game-links v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_DONGHANG"></leisure-comp-donghang-game-links>-->
<!--        <leisure-comp-crown-game-links v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_CROWN"></leisure-comp-crown-game-links>-->
<!--        <leisure-comp-lotus-game-links v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_LOTUS"></leisure-comp-lotus-game-links>-->
<!--        <leisure-comp-sky-game-links v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_SKY"></leisure-comp-sky-game-links>-->
        <leisure-comp-bet365-game-links v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_BET365"></leisure-comp-bet365-game-links>
        <router-view></router-view>
      </div>
    </div>
    <right-bar-comp>
      <!--내정보-->
      <user-info-comp slot="userinfo"></user-info-comp>
      <right-buttons-comp slot="btns"></right-buttons-comp>
      <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
    </right-bar-comp>
    <scroll-top-comp></scroll-top-comp>

    <!--푸트메뉴-->
    <foot-comp></foot-comp>
  </div>

</template>

<script>
  import RightBarComp from "../../components/RightBarComp";
  import SubTitle from "../../components/SubTitle";
  import LeftBarComp from "../../components/LeftBarComp";
  import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
  import sportsConst from "../../common/sportsConst";
  import leisureConst from "../../common/leisureConst";
  import {getOnBetLeisureGames} from "../../network/leisureRequest";
  import ScrollTopComp from "../../components/ScrollTopComp";
  import TopbarComp from "../../components/TopbarComp";
  import FootComp from "../../components/FootComp";
  import RightBarBannerComp from "../../components/RightBarBannerComp";
  import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
  import LeisureGameLinks from "./LeisureGameLinks";
  import LeisureGameCompLinks from "./LeisureCompLinks";
  import LeisureCompGameLinks from "./LeisureCompDonghangGameLinks";
  import LeisureCompDonghangGameLinks from "./LeisureCompDonghangGameLinks";
  import LeisureCompCrownGameLinks from "./LeisureCompCrownGameLinks";
  import LeisureCompLotusGameLinks from "./LeisureCompLotusGameLinks";
  import {postionMixin} from "../../common/mixin";
  import LeisureCompSkyGameLinks from "./LeisureCompSkyGameLinks";
  import LeisureCompBet365GameLinks from "./LeisureCompBet365GameLinks";
  import UserInfoComp from "../../components/UserInfoComp";
  import RightButtonsComp from "../../components/RightButtonsComp";
  import i18nConst from "../../common/i18nConst";

  export default {
    name: "L",
    mixins:[postionMixin],
    components: {
      RightButtonsComp,
      UserInfoComp,
      LeisureCompBet365GameLinks,
      LeisureCompSkyGameLinks,
      LeisureCompLotusGameLinks,
      LeisureCompCrownGameLinks,
      LeisureCompDonghangGameLinks,
      LeisureCompGameLinks,
      LeisureGameCompLinks,
      LeisureGameLinks,
      SportsLeftBarComp,
      RightBarBannerComp,
      FootComp, TopbarComp, ScrollTopComp, LeisureLeftComp, LeftBarComp, SubTitle, RightBarComp
    },
    data(){
      return {
        i18nConst:i18nConst,
        leisureConst:leisureConst,
        position:"미니게임",
      }
    },
    created() {
    }

  }
</script>

<style scoped>
  @import url("../../assets/css/minigame.css");
</style>