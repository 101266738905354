<template>
        <div>
            <div class="leisure_button_group_sub">
                <router-link tag="button" class="btn03 bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP}"
                             :to="{path:'/leisure/bet365_premiership', query: {t: new Date().getTime()}}"><i class="fa fa-soccer-ball-o"></i> {{i18nConst.GAME['PREMIERSHIP'][$store.state.country]}}</router-link>

                <router-link tag="button" class="btn03 bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE}"
                             :to="{path:'/leisure/bet365_superleague', query: {t: new Date().getTime()}}"><i class="fa fa-soccer-ball-o"></i> {{i18nConst.GAME['SUPERLEAGUE'][$store.state.country]}}</router-link>

                <router-link tag="button" class="btn03 bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_SOCCER_EUROCUP}"
                             :to="{path:'/leisure/bet365_eurocup', query: {t: new Date().getTime()}}"><i class="fa fa-soccer-ball-o"></i> {{i18nConst.GAME['EURO_CUP'][$store.state.country]}}</router-link>

                <router-link tag="button" class="btn03 bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_SOCCER_WORLDCUP}"
                             :to="{path:'/leisure/bet365_worldcup', query: {t: new Date().getTime()}}"><i class="fa fa-soccer-ball-o"></i> {{i18nConst.GAME['WORLD_CUP'][$store.state.country]}}</router-link>


            </div>
            <div class="leisure_button_group_sub">
                <router-link tag="button" class="btn03 bw2 mt5"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK}"
                             :to="{path:'/leisure/bet365_goldenhill', query: {t: new Date().getTime()}}"><i class="fa fa-coffee"></i> {{i18nConst.GAME['GOLDENHILL'][$store.state.country]}}</router-link>

                <router-link tag="button" class="btn03 bw2 mt5"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK}"
                             :to="{path:'/leisure/bet365_hillside', query: {t: new Date().getTime()}}"><i class="fa fa-coffee"></i> {{i18nConst.GAME['HILLSIDE'][$store.state.country]}}</router-link>

            </div>
        </div>




</template>
<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";
    import i18nConst from "../../common/i18nConst";

    export default {
        name: "LeisureCompBet365GameLinks",
        props: {
            comp: {
                type: Number,
                default() {
                    return -1
                }
            },
        },
        data(){
            return {
                i18nConst:i18nConst,
                leisureConst:leisureConst,
            }
        }
    }
</script>

<style scoped>

</style>